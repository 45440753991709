<template>
  <div>
    <h5
    style="margin-bottom: 0 !important; margin-top: 15px !important"
     class="my-4 mx-5 text-center">{{ $t('restoreYourPassword') }}</h5>
    <form
      @submit.prevent
      :class="[{'blue double loading':isLoading}]"
      class="ui form w-100 text-center align-items-center justify-content-center auth-form"
      novalidate>

      <p style="margin-bottom: 5px">{{ $t('recoveryPasswordEmailWillBeSent') }}</p>

      <TextField
        :model-value="form.email"
        @update:model-value="form.email=$event"
        type="email"
        :label="$t('form.email')"
        :forgotPassword = "true"
        @change="emailNotExists=false"
        />

        <Button
          class="mb-3"
          color="secondary"
          :text="$t('buttons.sendEmail')"
          @clicked="onSubmit" />

      <div v-if="v$.form.email.$error" class="mb-2">
          <span class="text-danger">{{ v$.form.email.$errors[0].$message }}</span>
      </div>
      <div v-else-if="emailNotExists && !(v$.form.email.$error)" class="mb-2">
          <span class="text-danger">{{ $t('errors.emailNotExists') }}</span>
      </div>

      <router-link style="font-size:inherit !important" to="/auth/signin">{{ $t("buttons.goBackToSignin") }}</router-link>

    </form>
  </div>
</template>

<script>
import Button from '@/components/form/Button'
import TextField from '@/components/form/TextField'
import { forgotPassword } from '@/api/auth'
// validations
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators/dist/raw.esm'

export default {
  name: 'AuthForgotPasswordSendConfirm',
  components: {
    TextField,
    Button
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      isLoading: false,
      emailNotExists: false,
      form: {
        email: ''
      }
    }
  },
  validations () {
    return {
      form: {
        email: {
          required: helpers.withMessage(this.$t('validations.email.required'), required),
          email: helpers.withMessage(this.$t('validations.email.invalid'), email)
        }
      }
    }
  },
  methods: {
    onSubmit () {
      this.emailNotExists = false
      this.v$.form.$touch()
      const formValid = !(this.v$.form.$error)

      if (formValid) {
        this.$store.dispatch('loading/setLoading', true)
        forgotPassword(this.form.email).then(() => {
          this.$router.push('/auth/emailSent')
        }).catch(() => {
          this.emailNotExists = true
        }).finally(() => {
          this.$store.dispatch('loading/setLoading', false)
        })
      }
    }
  }
}
</script>

<style scoped>
  p {
    color:gray;
    font-size: 1rem;
    margin-top: 0;
  }

  h5 {
    font-size: 1.5rem !important;
  }
</style>
